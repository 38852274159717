import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useIntl } from 'gatsby-plugin-react-intl';
import { connect } from 'react-redux';
import { sendEvent } from '../utils/amplitude';
import ClipsLayout from '../layouts/Clips/ClipsLayout';

const PageClips = () => {
	const intl = useIntl();

	const metaImage = `/clips/meta/clips-meta-${intl.locale}.jpg`;

	return (
		<>
			<Helmet>
				<meta charSet="utf-8" />
				<meta httpEquiv="Content-Type" content="text/html; charset=utf-8" />
				<meta name="format-detection" content="telephone=no" />
				<meta httpEquiv="x-dns-prefetch-control" content="on" />
				<meta name="viewport" content="width=device-width, initial-scale=1.0" />
				<title>{intl.formatMessage({ id: 'clips.meta.title' })}</title>
				<meta name="description" content={intl.formatMessage({ id: 'clips.meta.description' })} />
				<meta property="og:title" content={intl.formatMessage({ id: 'clips.meta.title' })} />
				<meta property="og:description" content={intl.formatMessage({ id: 'clips.meta.description' })} />
				<meta property="og:image" content={metaImage} />
				<meta property="og:image:secure_url" content={metaImage} />
				<meta property="og:image:type" content="image/jpg" />
				<meta property="og:image:width" content="1200" />
				<meta property="og:image:height" content="630" />
				<meta name="twitter:site" content="@scope_gg" />
				<meta name="twitter:card" content="summary_large_image" />
				<meta name="twitter:title" content={intl.formatMessage({ id: 'clips.meta.title' })} />
				<meta name="twitter:description" content={intl.formatMessage({ id: 'clips.meta.description' })} />
				<meta name="twitter:image" content={metaImage} />
				<link rel="icon" href="/favicon.ico" />
				{intl.locale === 'en' ? (
					<link rel="canonical" href="https://scope.gg/" />
				) : (
					<link rel="canonical" href={`https://scope.gg/${intl.locale}/`} />
				)}
			</Helmet>
			<ClipsLayout />
		</>
	);
};

const mapDispatchToProps = (dispatch) => ({});

export default connect(null, mapDispatchToProps)(PageClips);
