import React, { useState, useEffect } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import * as styles from './clipspromo.module.scss';

const ClipsPromo = ({ onClickRegister }) => {
	const intl = useIntl();

	return (
		<section className={styles.clipspromo}>
			<div className={styles.clips_container_left}>
				<div className={styles.clipspromo_wrapper}>
					<div className={styles.clipspromo_content}>
						<div className={styles.clipspromo_logo}>
							<img src='/clips/scope-logo.svg' alt="SCOPE.GG" />
						</div>
						<div className={styles.clipspromo_title}>
							{intl.formatMessage({ id: 'clips.promo.title' })}
						</div>
						<div className={styles.clipspromo_desc}>
							{intl.formatMessage({ id: 'clips.promo.desc' })}
						</div>
						<div className={styles.clipspromo_button}>
							<button className={styles.clips_button} onClick={onClickRegister}>
								{intl.formatMessage({ id: 'clips.promo.button' })}
							</button>
						</div>
						<div className={styles.clipspromo_disclaimer}>
							{intl.formatMessage({ id: 'clips.promo.disclaimer' })}
						</div>
					</div>
					<div className={styles.clipspromo_img}>
						<img src='/clips/promo-bg.webp' />
					</div>
				</div>
				<div className={styles.clipspromo_features}>
					<div className={styles.clipspromo_features__item}>
						<div className={styles.clipspromo_features__item__title}>
							{intl.formatMessage({ id: 'clips.promo.features.one.title' })}
						</div>
						<div className={styles.clipspromo_features__item__desc}>
							{intl.formatMessage({ id: 'clips.promo.features.one.desc' })}
						</div>
					</div>
					<div className={styles.clipspromo_features__item}>
						<div className={styles.clipspromo_features__item__title}>
							{intl.formatMessage({ id: 'clips.promo.features.two.title' })}
						</div>
						<div className={styles.clipspromo_features__item__desc}>
							{intl.formatMessage({ id: 'clips.promo.features.two.desc' })}
						</div>
					</div>
					<div className={styles.clipspromo_features__item}>
						<div className={styles.clipspromo_features__item__img}>
							<img src='/clips/pro-players.webp' alt={intl.formatMessage({ id: 'clips.promo.features.three.desc' })} />
						</div>
						<div className={styles.clipspromo_features__item__desc}>
							{intl.formatMessage({ id: 'clips.promo.features.three.desc' })}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ClipsPromo;
