import React, { useState, useEffect } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import * as styles from './clipswatch.module.scss';

const ClipsWatch = ({ onClickRegister }) => {
	const intl = useIntl();

	return (
		<section className={styles.clipswatch}>
			<div className={styles.clips_container}>
				<div className={styles.clipswatch__wrapper}>
					<div className={styles.clipswatch__title}>
						{intl.formatMessage({ id: 'clips.watch.title' })}
					</div>
					<button className={styles.clips_button} onClick={onClickRegister}>
						{intl.formatMessage({ id: 'clips.watch.button' })}
					</button>
				</div>
			</div>
		</section>
	);
};

export default ClipsWatch;
