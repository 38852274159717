import React, { useEffect, useState } from 'react';
import * as styles from './clips.module.scss';
import { authService } from '../../services/authService';
import { setLoggedIn, setUserData } from '../../state/auth/actions';
import { connect } from 'react-redux';
import { isBrowser } from '../../utils/ssr';
import { useIntl } from 'gatsby-plugin-react-intl';
import { useSelector } from 'react-redux';
import { mixPanelService } from '../../services/mixPanelService';
import Favicon from '../../assets/images/favicon.ico';
import CS2LoginModal from '../../components/modals/CS2LoginModal';
import { setLoginPopupOpen } from '@src/state/loginPopup/actions';
import LoginPopup from '@components/molecules/LoginPopup';
import ClipsPromo from '../../components/molecules/clips/ClipsPromo';
import ClipsWhat from '../../components/molecules/clips/ClipsWhat';
import ClipsHow from '../../components/molecules/clips/ClipsHow';
import ClipsConnect from '../../components/molecules/clips/ClipsConnect';
import ClipsWatch from '../../components/molecules/clips/ClipsWatch';
import Header from '@components/molecules/Header';
import Footer from '@components/molecules/Footer';
import SearchPlayer from '@components/molecules/SearchPlayer';
import UrlBuilder from '@src/utils/urlBuilder';

const ClipsLayout = ({ setIsLoggedIn, setUserData, isLoginPopupOpen, setLoginPopupOpen }) => {
	const intl = useIntl();
	const lang = intl.locale;
	const account = useSelector((state) => state.authReducer);

	const [playerSearchVisible, setPlayerSearchVisible] = useState(false);
	const [chaseButtonVisible, setChaseButtonVisible] = useState(false);
	const [signUpUrl, setSignUpUrl] = useState('');

	useEffect(() => {
		setTimeout(() => {
			mixPanelService.sendEvent({
				event: 'Loadpage Clip Landing',
				properties: {},
			});
		}, 1500);
	}, []);

	useEffect(() => {
		checkingAuthorization();
		init();
	}, []);

	const checkingAuthorization = async () => {
		const profile = await authService.getUserData();

		if (profile.isLoggedIn && isBrowser) {
			window.location.href = `${window.location.protocol}//app.${window.location.hostname}`;
		}
	};

	const init = async () => {
		const profile = await authService.getUserData();

		if (profile.isLoggedIn) {
			setIsLoggedIn(true);
			setUserData(profile.accountData);
		}
	};

	const handleSearchPlayerClick = () => {
		if (playerSearchVisible) return;

		setPlayerSearchVisible(true);
	};

	const handleSearhPlayerExit = () => {
		if (!playerSearchVisible) return;

		setPlayerSearchVisible(false);
	};

	const handleScroll = () => {
		const scrollPosition = window.scrollY;
		const offsetIndex = !isMobile ? window.innerHeight * 0.8 : window.innerHeight * 0.8;

		if (scrollPosition > offsetIndex) {
			setChaseButtonVisible(true);
		} else {
			setChaseButtonVisible(false);
		}
	};

	// useEffect(() => {
	// 	if (isLoginPopupOpen) {
	// 		document.body.style.overflowY = 'hidden';
	// 	} else {
	// 		document.body.style.overflowY = 'scroll';
	// 	}
	// }, [isLoginPopupOpen]);

	const setUpLinks = () => {
		const appUrl = process.env.GATSBY_APP_URL;

		const signUpLink = new UrlBuilder(`${appUrl}/signup`);

		setSignUpUrl(signUpLink.build());
	};

	useEffect(() => {
		if (isBrowser) {
			setUpLinks();
		}
	}, []);

	const onClickRegister = async () => {
		await mixPanelService.sendEvent({
			event: 'Clip Landing / Click Sign Up Button',
			properties: {
				location: 'body'
			},
		});

		const appUrl = process.env.GATSBY_APP_URL;
		if (process.env.GATSBY_ACTIVE_ENV === 'development') {
			window.location = `http://master.frontend.stats-staging.services/${lang !== 'en' ? lang + '/' : ''}signup`;
		} else {
			window.location = `${appUrl}/${lang !== 'en' ? lang + '/' : ''}signup`;
		}
	}

	return (
		<>
			{playerSearchVisible ? (
				<SearchPlayer onClose={handleSearhPlayerExit} />
			) : (
				<div className={styles.main}>
					<Header fromLanding="clips" onSearchPlayer={handleSearchPlayerClick} />
					<ClipsPromo onClickRegister={onClickRegister} />
					<ClipsWhat />
					<ClipsHow />
					<ClipsConnect onClickRegister={onClickRegister} />
					<ClipsWatch onClickRegister={onClickRegister} />
					<Footer />
				</div>
			)}
			<LoginPopup
				fromLanding="clips"
				isOpen={isLoginPopupOpen}
				onClose={() => setLoginPopupOpen(false)}
			/>
		</>
	);
};

const mapStateToProps = (state) => ({
	isLoginPopupOpen: state.loginPopupReducer.isOpen,
});

const mapDispatchToProps = (dispatch) => ({
	setIsLoggedIn: (payload) => dispatch(setLoggedIn(payload)),
	setUserData: (payload) => dispatch(setUserData(payload)),
	setLoginPopupOpen: (payload) => dispatch(setLoginPopupOpen(payload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClipsLayout);
