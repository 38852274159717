import React, { useState, useEffect } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import * as styles from './clipswhat.module.scss';

const ClipsWhat = () => {
	const intl = useIntl();
	const lang = intl.locale;

	return (
		<section className={styles.clipswhat}>
			<div className={styles.clips_container_left}>
				<div className={styles.clipswhat__wrapper}>
					<div className={styles.clipswhat__content}>
						<div className={styles.clipswhat__subtitle}>
							{intl.formatMessage({ id: 'clips.what.title' })}
						</div>
						<div className={styles.clipswhat__title}>
							{intl.formatMessage({ id: 'clips.what.subtitle' })}
						</div>
						<div className={styles.clipswhat__desc}>
							{intl.formatMessage({ id: 'clips.what.desc' })}
						</div>
					</div>
					<div className={styles.clipswhat__img}>
						<img src={`/clips/what-bg-${lang}.webp`} />
					</div>
				</div>
				<div className={styles.clipswhat__list}>
					<div className={styles.clipswhat__item}>
						<div className={styles.clipswhat__item__img}>
							<img src="/clips/what-1.webp" />
						</div>
						<div className={styles.clipswhat__item__title}>
							{intl.formatMessage({ id: 'clips.what.list.one.title' })}
						</div>
						<div className={styles.clipswhat__item__desc}>
							{intl.formatMessage({ id: 'clips.what.list.one.desc' })}
						</div>
					</div>
					<div className={styles.clipswhat__item}>
						<div className={styles.clipswhat__item__img}>
							<img src={`/clips/what-2-${lang}.webp`} />
						</div>
						<div className={styles.clipswhat__item__title}>
							{intl.formatMessage({ id: 'clips.what.list.two.title' })}
						</div>
						<div className={styles.clipswhat__item__desc}>
							{intl.formatMessage({ id: 'clips.what.list.two.desc' })}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ClipsWhat;
