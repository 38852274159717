import React, { useState, useEffect } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import * as styles from './clipsconnect.module.scss';

const ClipsConnect = ({ onClickRegister }) => {
	const intl = useIntl();
	const lang = intl.locale;

	return (
		<section className={styles.clipsconnect}>
			<div className={styles.clipsconnect__header}>
				<div className={styles.clipsconnect__header__title}>
					{intl.formatMessage({ id: 'clips.connect.title' })}
				</div>
				<div className={styles.clipsconnect__header__button}>
					<button className={styles.clips_button} onClick={onClickRegister}>
						{intl.formatMessage({ id: 'clips.connect.button' })}
					</button>
					<div className={styles.clipsconnect__header__disclaimer}>
						{intl.formatMessage({ id: 'clips.connect.disclaimer' })}
					</div>
				</div>
			</div>
			<div className={styles.clipsconnect__list}>
				<div className={styles.clipsconnect__item}>
					<img src='/clips/connect-1.webp' />
					<div className={styles.clipsconnect__item__step}>01</div>
					<div className={styles.clipsconnect__item__title}>
						{intl.formatMessage({ id: 'clips.connect.list.one.title' })}
					</div>
				</div>
				<div className={styles.clipsconnect__item}>
					<img src='/clips/connect-2.webp' />
					<div className={styles.clipsconnect__item__step}>02</div>
					<div className={styles.clipsconnect__item__title}>
						{intl.formatMessage({ id: 'clips.connect.list.two.title' })}
					</div>
				</div>
				<div className={styles.clipsconnect__item}>
					<img src={`/clips/connect-3-${lang}.webp`} />
					<div className={styles.clipsconnect__item__step}>03</div>
					<div className={styles.clipsconnect__item__title}>
						{intl.formatMessage({ id: 'clips.connect.list.three.title' })}
					</div>
				</div>
			</div>
		</section>
	);
};

export default ClipsConnect;
