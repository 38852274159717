import React, { useState, useEffect } from 'react';
import { useIntl } from 'gatsby-plugin-react-intl';
import * as styles from './clipshow.module.scss';

const ClipsHow = () => {
	const intl = useIntl();

	return (
		<section className={styles.clipshow}>
			<div className={styles.clips_container}>
				<div className={styles.clipshow__title}>
					{intl.formatMessage({ id: 'clips.how.title' })}
				</div>
				<div className={styles.clipshow__desc}>
					{intl.formatMessage({ id: 'clips.how.desc' })}
				</div>
				<div className={styles.clipshow__wrapper}>
					<div className={styles.clipshow__item}>
						<img src="/clips/how-1.svg" />
						<div className={styles.clipshow__item__title}>
							{intl.formatMessage({ id: 'clips.how.list.one.title' })}
						</div>
						<div className={styles.clipshow__item__desc}>
							{intl.formatMessage({ id: 'clips.how.list.one.desc' })}
						</div>
					</div>
					<div className={styles.clipshow__item}>
						<img src="/clips/how-2.svg" />
						<div className={styles.clipshow__item__title}>
							{intl.formatMessage({ id: 'clips.how.list.two.title' })}
						</div>
						<div className={styles.clipshow__item__desc}>
							{intl.formatMessage({ id: 'clips.how.list.two.desc' })}
						</div>
					</div>
					<div className={styles.clipshow__item}>
						<img src="/clips/how-3.svg" />
						<div className={styles.clipshow__item__title}>
							{intl.formatMessage({ id: 'clips.how.list.three.title' })}
						</div>
						<div className={styles.clipshow__item__desc}>
							{intl.formatMessage({ id: 'clips.how.list.three.desc' })}
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default ClipsHow;
